export const expSequenceMap = {
  'nonempty block': [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  'odd size block': [
    1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
  ],
  'even size block': [
    0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0,
  ],
  'all permutations, factorials cycle': [
    1, 1, 2, 6, 24, 120, 720, 5040, 40320, 362880, 3628800, 39916800, 479001600,
    6227020800, 87178291200,
  ],
  'odd cycle': [
    1, 0, 2, 0, 24, 0, 720, 0, 40320, 0, 3628800, 0, 479001600, 0, 87178291200,
  ],
  'even cycle': [1, 0, 6, 0, 120, 0, 5040, 0, 362880, 0, 39916800],
  derangements: [
    1, 0, 1, 2, 9, 44, 265, 1854, 14833, 133496, 1334961, 14684570, 176214841,
  ],
  'bell numbers': [
    1, 1, 2, 5, 15, 52, 203, 877, 4140, 21147, 115975, 678570, 4213597,
    27644437,
  ],
  'tennis pairs': [
    1, 0, 1, 0, 3, 0, 15, 0, 105, 0, 945, 0, 10395, 0, 135135, 0, 2027025, 0,
    34459425,
  ],
  'telephone exchange': [
    1, 1, 2, 4, 10, 26, 76, 232, 764, 2620, 9496, 35696, 140152, 568504,
    2390480, 10349536,
  ],
  tan: [1, 0, 2, 0, 16, 0, 272, 0, 7936, 0, 353792, 0, 22368256, 0, 1903757312],
  arcsin: [
    1, 0, 1, 0, 9, 0, 225, 0, 11025, 0, 893025, 0, 108056025, 0, 18261468225,
  ],
  'one odd cyle': [1, 0, 2, 0, 6, 0, 24, 0, 720],
  'all odd cycles': [
    1, 1, 1, 3, 9, 45, 225, 1575, 11025, 99225, 893025, 9823275, 108056025,
    1404728325,
  ],
  'one even cycle': [
    1, 0, 6, 0, 120, 0, 5040, 0, 362880, 0, 39916800, 0, 6227020800, 0,
    1307674368000,
  ],
  'all even cycles': [
    1, 0, 1, 0, 9, 0, 225, 0, 11025, 0, 893025, 0, 108056025, 0, 18261468225, 0,
    4108830350625,
  ],
  ones: [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ],
  'hex numbers': [
    1, 1, 3, 10, 36, 137, 543, 2219, 9285, 39587, 171369, 751236, 3328218,
    14878455, 67030785,
  ],
  'ternary numbers': [
    1, 1, 3, 12, 55, 273, 1428, 7752, 43263, 246675, 1430715, 8414640, 50067108,
    300830572, 1822766520, 11124755664, 68328754959, 422030545335,
    2619631042665, 16332922290300, 102240109897695, 642312451217745,
    4048514844039120,
  ],
  'ternary squared': [
    1, 2, 7, 30, 143, 728, 3876, 21318, 120175, 690690, 4032015, 23841480,
    142498692, 859515920, 5225264024, 31983672534, 196947587823, 1219199353190,
    7583142491925, 47365474641870, 296983176369495, 1868545312633440,
  ],
  zeros: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0,
  ],
  '1/(2 - e^z)': [
    1, 3, 13, 75, 541, 4683, 47293, 545835, 7087261, 102247563, 1622632573,
    28091567595, 526858348381,
  ],
  'ln(1/(2 - e^z))': [
    1, 2, 6, 26, 150, 1082, 9366, 94586, 1091670, 14174522, 204495126,
    3245265146,
  ],
}

export const expSequenceNames = Object.keys(expSequenceMap);

export const options = expSequenceNames.map((sequenceName, index) => {
  return {
    id: index,
    name: sequenceName,
    sequence: expSequenceMap[sequenceName],
  };
});
